<template>
  <div class="CustomQuestionListContainer">
    <div class="Aff-TestAnalysis-Header">
      <strong><a style="color: #642c90">Custom Questions List</a></strong>
    </div>
    <div class="divider linediv hide-on-small-only" style="width: 100%"></div>
    <div class="card-panel">
      <div class="Custom_list">
        <div>
          <div class="Customlist_Header"><strong>Course</strong></div>
          <select
            class="browser-default Customlist_DropDown"
            v-model="CustomCourseId"
            @change="OnCourseChange($event)"
          >
            <option value="" disabled selected>---select--</option>
            <option
              v-for="CData in CourseList"
              :key="CData.CourseId"
              :value="CData.AffiliationCourseId"
            >
              {{ CData.CourseName }}
            </option>
          </select>
          <div class="validation" v-if="CourseValidation">Please Select Course</div>
        </div>
        <div>
          <div class="Customlist_Header"><strong>Subject</strong></div>
          <select
            class="browser-default Customlist_DropDown"
            v-model="CustomSubjectId"
            @change="OnSubjectiveChange($event)"
          >
            <option value="" disabled selected>-- select --</option>
            <option v-for="SData in SubjectList" :key="SData.SubjectId" :value="SData.SubjectId">
              {{ SData.SubjectName }}
            </option>
          </select>
          <div class="validation" v-if="SubjectValidation">Please Select Subject</div>
        </div>
        <div class="">
          <div class="Customlist_Header"><strong>Topic</strong></div>
          <div class="Customlist_DropDown">
            <VueMultiselect
              v-model="CustomTopicId"
              :options="TopicList"
              @update:model-value="updateSelectedTopic"
              placeholder=""
              label="TopicName"
              track-by="TopicId"
              :multiple="true"
              :limit="1"
              :close-on-select="false"
              @tag="addTopicTag"
            >
              <template v-slot:beforeList>
                <div class="row">
                  <a
                    class="multiselectBtn waves-effect waves-light btn getclassbutton"
                    @click="TopicselectNone"
                    >none</a
                  >&nbsp;
                  <a
                    class="multiselectBtn waves-effect waves-light btn getclassbutton"
                    @click="TopicselectAll"
                    >all</a
                  >
                </div>
              </template>
            </VueMultiselect>
          </div>
          <div class="validation" v-if="TopicValidation">Please Select Topic</div>
        </div>
        <div class="">
          <div class="Customlist_Header"><strong>Chapter</strong></div>
          <div class="Customlist_DropDown">
            <VueMultiselect
              v-model="CustomChapterId"
              :options="ChapterList"
              @update:model-value="updateSelectedChapter"
              placeholder=""
              label="ChapterName"
              track-by="ChapterId"
              :multiple="true"
              :limit="1"
              :close-on-select="false"
              @tag="addChapterTag"
            >
              <template v-slot:beforeList>
                <div class="row">
                  <a
                    class="multiselectBtn waves-effect waves-light btn getclassbutton"
                    @click="ChapterselectNone"
                    >none</a
                  >&nbsp;
                  <a
                    class="multiselectBtn waves-effect waves-light btn getclassbutton"
                    @click="ChapterselectAll"
                    >all</a
                  >
                </div>
              </template>
            </VueMultiselect>
          </div>
          <div class="validation" v-if="ChapterValidation">Please Select Chapter</div>
        </div>
      </div>
      <div>
        <a
          class="CList_SubBtn waves-effect waves-light btn getclassbutton"
          @click="getCustomQuestionList()"
          >Submit</a
        >
      </div>
    </div>
    <div class="Table">
      <table class="qtl">
        <thead class="TableHeader">
          <tr class="titlebar">
            <td class="left">Custom Questions - {{ this.CustomQuestionList.length }}</td>
          </tr>
        </thead>
        <tbody class="QuestionList">
          <div v-for="(Questions, index) in CustomQuestionList" :key="index">
            <!-- {{index+1}} -->
            <i
              class="icon material-icons"
              style="color: #642c90; cursor: pointer"
              @click="editCustomQuestion(Questions)"
              >edit</i
            >
            <!-- <div> -->
            <div style="width: 100%; height: 205px; background-color: white">
              <img
                class="QuestionImage card-panel"
                style="width: 100%; height: 205px"
                :src="`${Questions.QuestionDiagramURL}`"
                draggable="false"
                v-if="!Questions.Q_LATEX"
              />
              <div style="padding: 20px">
                <vue-mathjax
                  :formula="Questions.Q_LATEX"
                  :options="VueMathJaxOptions"
                  :safe="false"
                />
              </div>
            </div>
          </div>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import VueMultiselect from "vue-multiselect";
import API from "../Api";
import Mobileapi from "../Mobileapi";

export default {
  data() {
    return {
      CourseList: [],
      SubjectList: [],
      TopicList: [],
      ChapterList: [],
      CourseIdData: [],
      CustomQuestionList: [],
      KeyCourseId: null,
      CustomCourseId: null,
      CustomSubjectId: null,
      CustomTopicId: null,
      CustomChapterId: null,
      CourseValidation: false,
      SubjectValidation: false,
      TopicValidation: false,
      ChapterValidation: false,
      courseBatchList: null,
      QuestionDiagramURL: null,
      showSolutionModal: false,
      selectedSubject: [],
      selectedTopic: [],
      selectedChaper: [],
      subjectId: null,
      topicId: null,
      chapterId: null,
      VueMathJaxOptions: {
        extensions: ["tex2jax.js"],
        jax: ["input/TeX", "output/HTML-CSS"],
        tex2jax: {
          inlineMath: [
            ["$", "$"],
            ["\\(", "\\)"],
          ],
          displayMath: [
            ["$$", "$$"],
            ["\\[", "\\]"],
          ],
          processEscapes: true,
          processEnvironments: true,
        },
        "HTML-CSS": {
          fonts: ["TeX"],
        },
      },
    };
  },
  activated() {
    API.getInstituteTestMeta({}, (response) => {
      this.CourseList = response.courses;
    });
  },
  components: {
    VueMultiselect,
  },
  methods: {
    editCustomQuestion(Questions) {
      this.$router.push({
        name: "AddCustomQuestion",
        params: {
          editQuestionId: Questions.QuestionId,
          // pageName: "CustomQuestionList",
          editCourseId: this.CustomCourseId,
          editSubjectId: this.CustomSubjectId,
          editTopicId: JSON.stringify(this.CustomTopicId),
          editChapterId: JSON.stringify(this.CustomChapterId),
        },
      });
    },
    OnCourseChange(event) {
      this.CustomCourseId = Number(event.target.value);
      this.$store.dispatch("showLoader", true);
      this.TopicList = [];
      this.CustomTopicId = [];
      this.ChapterList = [];
      this.CustomChapterId = [];
      API.getInstituteTestMeta(
        {
          affiliationCourseId: this.CustomCourseId,
        },
        (response) => {
          this.$store.dispatch("showLoader", false);
          this.SubjectList = response.subjects;
          for (let i = 0; i < response.courses.length; i += 1) {
            if (Number(response.courses[i].AffiliationCourseId) === Number(event.target.value)) {
              this.courseBatchList = response.courses[i].CourseId;
            }
          }
        },
      );
      this.CustomSubjectId = null;
      if (this.CustomCourseId !== null) {
        this.CourseValidation = false;
      }
    },
    OnSubjectiveChange(event) {
      this.CustomSubjectId = Number(event.target.value);
      this.$store.dispatch("showLoader", true);
      this.TopicList = [];
      this.CustomTopicId = [];
      this.ChapterList = [];
      this.CustomChapterId = [];
      API.getInstituteTestMeta(
        {
          affiliationCourseId: this.CustomCourseId,
          subjects: this.CustomSubjectId,
        },
        (response) => {
          this.$store.dispatch("showLoader", false);
          this.TopicList = response.topics;
        },
      );
      this.CustomTopicId = null;
      if (this.CustomSubjectId !== null) {
        this.SubjectValidation = false;
      }
    },
    addTopicTag(newTag) {
      const tag = {
        TopicName: newTag,
        TopicId: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000),
      };
      this.TopicList.push(tag);
      this.CustomTopicId.push(tag);
    },
    updateSelectedTopic(value) {
      this.CustomChapterId = null;
      this.ChapterList = [];
      this.topicId = null;
      if (this.CustomTopicId.length >= 0) {
        this.selectedTopic = [];
      }
      if (this.CustomTopicId.length === 0) {
        this.ChapterList = [];
        this.CustomChapterId = null;
      }
      this.$store.dispatch("showLoader", true);
      value.forEach((resource) => {
        this.selectedTopic.push(resource);
      });
      const topic = this.selectedTopic.map((a) => a.TopicId);
      this.topicId = topic.toString();
      if (this.topicId.length > 0) {
        Mobileapi.getListOfChapters(
          {
            TopicId: this.topicId,
          },
          (chapRes) => {
            this.ChapterList = chapRes.data;
          },
        );
        // this.$store.dispatch("showLoader", true);
        // API.getInstituteTestMeta({
        //   affiliationCourseId: this.CustomCourseId,
        //   subjects: this.CustomSubjectId,
        //   topics: this.topicId.toString(),
        // }, (response) => {
        //   this.ChapterList = response.chapters;
        //   this.$store.dispatch("showLoader", false);
        // });
      }
      this.$store.dispatch("showLoader", false);
      if (this.CustomTopicId !== null) {
        this.TopicValidation = false;
      }
    },
    TopicselectNone() {
      this.CustomTopicId = null;
      this.CustomChapterId = null;
      this.ChapterList = [];
      this.CustomChapterId = [];
    },
    TopicselectAll() {
      this.CustomTopicId = this.TopicList;
      const topic = this.TopicList.map((a) => a.TopicId);
      this.topicId = topic.toString();
      this.$store.dispatch("showLoader", true);
      Mobileapi.getListOfChapters(
        {
          TopicId: this.topicId,
        },
        (chapRes) => {
          this.ChapterList = chapRes.data;
          this.$store.dispatch("showLoader", false);
        },
      );
      //   API.getInstituteTestMeta({
      //     affiliationCourseId: this.CustomCourseId,
      //     subjects: this.CustomSubjectId,
      //     topics: this.topicId.toString(),
      //   }, (response) => {
      //     this.ChapterList = response.chapters;
      //     this.$store.dispatch("showLoader", false);
      //   });
      if (this.CustomTopicId !== null) {
        this.TopicValidation = false;
      }
      this.CustomChapterId = null;
    },
    addChapterTag(newTag) {
      const tag = {
        ChapterName: newTag,
        ChapterId: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000),
      };
      this.ChapterList.push(tag);
      this.CustomChapterId.push(tag);
    },
    updateSelectedChapter(value) {
      if (this.CustomChapterId.length >= 0) {
        this.selectedChaper = [];
      }
      value.forEach((resource) => {
        this.selectedChaper.push(resource);
      });
      const chapter = this.selectedChaper.map((a) => a.ChapterId);
      this.chapterId = chapter.toString();
      if (this.CustomChapterId !== null) {
        this.ChapterValidation = false;
      }
    },
    ChapterselectNone() {
      this.CustomChapterId = null;
    },
    ChapterselectAll() {
      this.CustomChapterId = this.ChapterList;
      const chapter = this.ChapterList.map((a) => a.ChapterId);
      this.chapterId = chapter.toString();
      if (this.CustomChapterId !== null) {
        this.ChapterValidation = false;
      }
    },
    getCustomQuestionList() {
      if (this.CustomCourseId === null) {
        this.CourseValidation = true;
      }
      if (this.CustomSubjectId === null) {
        this.SubjectValidation = true;
      }
      if (this.CustomTopicId === null) {
        this.TopicValidation = true;
      }
      if (this.CustomChapterId === null) {
        this.ChapterValidation = true;
      }
      if (
        this.CustomCourseId !== null &&
        this.CustomSubjectId !== null &&
        this.CustomTopicId !== null &&
        this.CustomChapterId !== null
      ) {
        const data = {
          CourseId: this.courseBatchList,
          SubjectId: this.CustomSubjectId,
          ChapterIds: this.chapterId,
        };
        this.$store.dispatch("showLoader", true);
        Mobileapi.getCustomQuestionsList(data, (response) => {
          this.CustomQuestionList = response.data;
          this.$store.dispatch("showLoader", false);
        });
      }
    },
    ViewQuestion(QuestionId) {
      this.QuestionDiagramURL = QuestionId;
      this.showSolutionModal = true;
    },
  },
};
</script>

<style scoped>
.Custom_list {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.Customlist_DropDown {
  border-radius: 5px;
  height: 36px;
  width: 267px;
  min-height: 40px;
}

.Customlist_Header {
  margin-bottom: 8px;
  font-size: 19px;
}

.CList_SubBtn {
  background: linear-gradient(90.2deg, #ff9421 0.16%, #fbad26 98.41%);
  border-radius: 5px;
  font-size: 14px;
  margin-top: 36px;
  width: 130px;
  height: 38px;
}

.validation {
  color: red;
}

.QuestionList {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  /* grid-template-columns: auto auto; */
  grid-gap: 30px;
}

.QuestionList .QuestionImage {
  width: 100%;
  border-radius: 5px;
  height: 202px;
  /* position: relative; */
}

table.qtl thead tr {
  background-color: #6a2f85;
  color: #fff;
}

table.qtl thead tr td:first-child {
  border-radius: 5px 0 0 5px;
}

table.qtl thead tr td {
  border-radius: 0;
}

.Aff-TestAnalysis-Header {
  margin-top: 19px;
  font-size: 25px;
  color: #642c90;
}

.linediv {
  margin-top: 18.5px;
  margin-bottom: 18.5px;
  height: 0px;
  left: 132px;
  border: 1px solid #e0e0e0;
}

@media screen and (max-width: 990px) and (min-width: 200px) {
  .Custom_list {
    flex-direction: column;
  }

  .Custom_list .Customlist_DropDown {
    width: 100%;
  }
}

/* @media screen and (max-width: 1335px) and (min-width: 991px) {
      .Custom_list {
          flex-direction: row;
      }

      .Custom_list .Customlist_DropDown {
          width: 200px;
      }
  } */

@media screen and (max-width: 800px) {
  .QuestionList {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 10px;
    align-items: center;
  }
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
